<template>
  <div class="relative bg-darkGreen py-32">
    <div class="custom-shape-divider-top-1712488877">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
    <div class="container">
      <h2 class="text-lg text-brightRed">
        Samstag - Sonntag 12. - 13. Juli 2025
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 class="mt-0 text-5xl tracking-wide text-white">Festival</h2>
        </div>
        <div class="text-gray-50 text-balance">
          Unser Festival beginnt am Samstagmorgen mit eurer Anreise. Danach
          könnt ihr entspannt den Campingplatz beziehen und euch mit dem Gelände
          vertraut machen. Ab nachmittags könnt ihr euch bei einem fetzigen
          Tagesprogramm mit anderen Gruppen messen und gemeinsam abfeiern.
          Abends starten unsere Acts. Weitere Informationen folgen bald.<br />
          <!-- <div class="flex items-center gap-1">
            <UIcon name="i-heroicons-chevron-double-right" class="size-3" /><NuxtLink to="/ticket">Sichere dir jetzt dein Ticket</NuxtLink
            ><UIcon name="i-heroicons-chevron-double-left" class="size-3" />
          </div> -->
        </div>
      </div>
    </div>
    <div class="custom-shape-divider-bottom-1712490367">
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style>
.custom-shape-divider-top-1712488877 {
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1712488877 svg {
  position: relative;
  display: block;
  width: calc(185% + 1.3px);
  height: 123px;
}

.custom-shape-divider-top-1712488877 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom-1712490367 {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1712490367 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 70px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1712490367 .shape-fill {
  fill: #ffffff;
}
</style>
