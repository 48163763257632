<template>
  <div class="py-6">
    <div class="container">
      <h2 class="text-darkestRed text-lg">Freitag 11. Juli 2025</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 class="mt-0 break-all text-5xl tracking-wide">Kulturabend</h2>
        </div>
        <div class="text-balance">
          Am Freitagabend stand Bernd Kohlhepp bei uns auf dem Hofgut Dürrenberg
          mit seinem neuen Programm „Hämmerle räumt auf“ beim Propellerfestival
          auf der Bühne. Ob das 2025 wieder so ist wird sich zeigen. Wir halten
          dich auf dem Laufenden. Mehr Infos zum letzten Jahr unter
          <NuxtLink to="/kulturstadel">Kulturstadel</NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style>
.wave1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave1 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 93px;
}

.wave1 .shape-fill {
  fill: #ffffff;
}
</style>
